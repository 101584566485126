import { createRouter, createWebHistory } from 'vue-router';
import Main  from '@/components/Main.vue';
import Pricelist from "@/components/Pricelist.vue";
import Contact from "@/components/Contact.vue"
import Team from "@/components/Team.vue"
import Gallery from "@/components/Gallery.vue"
import Estethic from "@/components/Estethic.vue"
import Cosmetology from "@/components/Cosmetology.vue"
import Laserotherapy from "@/components/Laserotherapy.vue"
import Dr_Zwolinska from "@/components/Dr_Zwolinska.vue"
import Dr_Zylkowska from "@/components/Dr_Zylkowska.vue"
import Blog from "@/views/Blog/Blog.vue"
import Post1  from '@/views/Blog/Post1.vue';
import Post2 from '@/views/Blog/Post2.vue';
import Post3 from '@/views/Blog/Post3.vue';
const routes = [
    {
        path: '/',
        name: '',
        component: Main,
    },
    {
        path: '/cennik',
        name: 'cennik',
        component: Pricelist,
    },
    {
        path: '/kontakt',
        name: 'kontakt',
        component: Contact,
    },
    {
        path: '/zespol',
        name: 'zespol',
        component: Team,
    },
    {
        path: '/galeria',
        name: 'galeria',
        component: Gallery,
    },
    {
        path: '/medycyna-estetyczna',
        name: 'medycyna-estetyczna',
        component: Estethic,
    },
    {
        path: '/kosmetologia',
        name: 'kosmetologia',
        component: Cosmetology,
    },
    {
        path: '/laseroterapia',
        name: 'laseroterapia',
        component: Laserotherapy,
    },
    {
        path: '/dr-zwolinska',
        name: 'dr-zwolinska',
        component: Dr_Zwolinska,
    },
    {
        path: '/dr-zylkowska',
        name: 'dr-zylkowska',
        component: Dr_Zylkowska,
    },
    {
        path: '/blog',
        name: 'Blog',
        component: Blog,
    },
    {
        path: '/blog/kosmetologiavsmedycynaestetyczna',
        name: 'Post1',
        component: Post1,
    },
    {
        path: '/blog/botoksczykwashialuronowy',
        name: 'Post2',
        component: Post2,
    },
    {
        path: '/blog/mityomedycynieestetycznej',
        name: 'Post3',
        component: Post3,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition; // powrót do zapisanej pozycji (np. przy cofaniu w przeglądarce)
      } else {
        return { top: 0 }; // przewijanie do góry
      }
    },
  });

export default router;