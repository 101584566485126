<template>
  <div class="blog-page">
    <!-- Carousel -->
    <div class="carousel-section" style="width: 80%; max-width: 500px; margin: 0 auto">
      <BCarousel 
        controls 
        indicators
        ride="carousel"
        :interval="3000"
        
        style="width: 100%;"
      >
        <BCarouselSlide 
          v-for="(post, index) in carouselPosts" 
          :key="`slide-${index}`"
          :img-src="post.image"
          :img-alt="post.imageAlt"
        >
          <div class="carousel-caption">
            <h3>{{ post.title }}</h3>
            <router-link :to="post.path" class="btn btn-light">
              Dowiedz się więcej
            </router-link>
          </div>
        </BCarouselSlide>
      </BCarousel>
    </div>

    <!-- Rest of your template -->
    <div id="grid" class="posts-grid row g-4 mt-5" style="max-width: 80%; margin: 0 auto; margin-bottom: 5%;">
      <div 
        v-for="post in allPosts"
        :key="post.id"
        class="col-md-4"
      >
        <div class="card h-100">
          <img 
            :src="post.thumbnail" 
            class="card-img-top"
            :alt="post.imageAlt"
          >
          <div class="card-body">
            <h5 class="card-title">{{ post.title }}</h5>
            <p class="card-text">{{ post.excerpt }}</p>
            <router-link 
              :to="post.path" 
              class="btn btn-primary"
            >
              Dowiedz się więcej
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BCarousel, BCarouselSlide } from 'bootstrap-vue-next'

export default {
  components: { BCarousel, BCarouselSlide },
  data() {
    return {
      allPosts: [
        {
          id: 1,
          title: 'Kosmetologia vs Medycyna Estetyczna',
          excerpt: 'Kosmetologia i medycyna estetyczna to dziedziny, które zyskały ogromną popularność w ostatnich latach. Dowiedz się, jakie są różnice między nimi, kiedy wybrać kosmetologa, a kiedy lekarza medycyny estetycznej, oraz jakie zabiegi są dostępne w każdej z tych dziedzin.',
          path: '/blog/kosmetologiavsmedycynaestetyczna',
          image: require('@/assetsPng/blog-images/blog2.png'),
          thumbnail: require('@/assetsPng/blog-images/blog1.thumbnail.png'),
          imageAlt: 'Kosmetologia vs Medycyna Estetyczna'
        },
        {
          id: 2,
          title: 'Botoks czy kwas hialuronowy? Różnice, zastosowania i efekty',
          excerpt: 'Botoks i kwas hialuronowy to dwa najpopularniejsze zabiegi medycyny estetycznej. Poznaj ich różnice, zastosowania, efekty oraz potencjalne ryzyka. Dowiedz się, który zabieg będzie najlepszy dla Twoich potrzeb.',
          path: '/blog/botoksczykwashialuronowy',
          image: require('@/assetsPng/blog-images/blog2.png'),
          thumbnail: require('@/assetsPng/blog-images/botoksthumbnail.jpg'),
          imageAlt: 'Botoks czy kwas hialuronowy'
        },
        {
          id: 3,
          title: 'Mity o medycynie estetycznej – co warto wiedzieć, zanim zdecydujesz się na zabieg?',
          excerpt: 'Medycyna estetyczna to dziedzina pełna mitów i nieporozumień. Obalamy najczęstsze mity, takie jak sztuczne efekty, bolesność zabiegów czy ich dostępność tylko dla kobiet. Dowiedz się, jak podejść do zabiegów świadomie i bezpiecznie.',
          path: '/blog/mityomedycynieestetycznej',
          image: require('@/assetsPng/blog-images/blog2.png'),
          thumbnail: require('@/assetsPng/blog-images/mitythumbnail.png'),
          imageAlt: 'Mity o medycynie estetycznej'
        },
      ]
    }
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    }
  },
  computed: {
    carouselPosts() {
      // Create unique instances for testing
      return this.allPosts.flatMap(post => [
        { ...post, uid: `${post.id}-1` },
      ])
    }
  }
}
</script>


<style scoped>

.blog-page{
  margin-top: 5%;
}
/* Add custom styling for carousel images */
.carousel-section ::v-deep(.carousel-inner) {
  border-radius: 8px;
  overflow: hidden;
}

.carousel-section ::v-deep(img) {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.carousel-caption {
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #bd924a;

  padding: 1rem;
  border-radius: 8px;
  bottom: 20%;
  text-align: center;
  max-width: 350px;
}

.btn-light {
  font-weight: 500;
}
#grid{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
</style>

